<template>
  <div ref="gradContainer" class="grad-container" @scroll="scrollElement">
    <div ref="header" class="header">
      <div class="back" @click="goBack">
        <div class="back-icon"></div>
      </div>
      <div class="title">抢红包</div>
    </div>
    <div ref="wechat" class="binding-wechat" @click="toWxBind">
      <div class="wechat-left">
        <div :class="{ 'wechat-icon': true, on: wechat }"></div>
        <div class="wechat-text">微信</div>
      </div>
      <div class="wechat-right">
        <div v-if="wechat" class="wechat-tips">已绑定</div>
        <div v-if="!wechat" class="wechat-tips">去绑定</div>
        <div v-if="!wechat" class="wechat-go-icon"></div>
      </div>
    </div>
    <div ref="content" class="content">
      <div v-if="isAnchorAccount" class="hand-red" @click="goRedBagConfig">
        <div class="hadn-info">
          <div class="hand-red-icon"></div>
          <div class="hand-text">直播互动发红包</div>
        </div>
        <div class="hand-go-icon"></div>
      </div>
      <div v-if="isAnchorAccount" class="border-gray"></div>
      <div class="red-list-header">
        <div class="red-list-title">领红包</div>
        <div class="red-detailed">
          <div class="detailed-tag" @click="goRedBagDetailed">红包明细</div>
          <div class="refresh" @click="handleRefresh">
            <div
              :class="{
                'refresh-icon': true,
                on: isRefresh,
              }"
            ></div>
          </div>
        </div>
      </div>
      <div class="red-list">
        <div v-for="(item, index) in bagList" :key="index" class="item-content">
          <div class="item" @click="handleRedBag(item)">
            <div :class="{ 'item-bg': true, on: item.highlight === 0 }"></div>
            <div class="top">
              <div :class="{ 'red-icon': true, on: item.highlight === 0 }"></div>
              <div class="red-text">恭喜发财，大吉大利！</div>
            </div>
            <div class="middle border"></div>
            <div class="bottom">
              <div class="user">
                <div class="user-head"></div>
                <div class="user-name">{{ item.nickname }}</div>
              </div>
              <div class="red-name">{{ item.type_text }}</div>
            </div>
          </div>
          <div class="tips-info">
            <div v-if="item.countDown" class="tips-timer">
              <div class="timer-icon"></div>
              <div class="timer-text">倒计时{{ item.countDown | countDownFilters }}</div>
            </div>
            <div v-if="item.level > 1" class="tips-grade">
              <div class="grade-icon"></div>
              <div class="grade-text">等级≥{{ item.level }}级</div>
            </div>
            <div v-if="item.first_role_create_hours" class="tips-first">
              <div class="first-icon"></div>
              <div class="first-text">首次创角{{ item.first_role_create_hours }}小时内</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="isLoaded" class="bottom-tips">我也是有底线的 ~</div> -->
    </div>
    <div v-if="bagForm.show" class="mask" @click="closeDialog"></div>
    <div v-if="bagForm.show" class="dialog">
      <div class="user-name">{{ bagForm.nickname }}的红包</div>
      <div class="bag-tips">{{ isReceive ? '手慢了，红包派完了' : '恭喜发财，大吉大利' }}</div>
      <div v-if="isReceive" class="go-detailed" @click="toGoDetail">
        <div class="detailed-text">看看大家的手气</div>
        <div class="detailed-icon"></div>
      </div>
      <div v-else :class="{ 'bag-icon': true, active: bagForm.isGetBag }" @click="handleClickBag"></div>
      <div class="colse-box" @click="closeDialog">
        <div class="close-dialog"></div>
      </div>
    </div>
    <div v-if="showBindGuide" class="wx-bind">
      <div class="bind-shade"></div>
      <div class="bind-content">
        <div class="close" @click="closeWXDialog">
          <img src="@/assets/imgs/x-btn.png" alt="" />
        </div>
        <h3 class="title">微信绑定步骤</h3>
        <div class="text">
          <p>1、打开微信，搜公众号<span class="light-text">“微游Club”</span></p>
          <p>2、关注<span class="light-text">“微游Club”</span>公众号</p>
          <p>3、点击<span class="light-text">“自助服务”</span> - <span class="light-text">“绑定微信”</span> 菜单</p>
          <p>4、输入绑定码，进行绑定</p>
          <p>5、绑定后返回游戏，即可提现</p>
        </div>
        <div class="exchange">
          <p>
            绑定码：<span class="light-text"> {{ exchangeCode }} </span>
          </p>
          <button v-clipboard:copy="exchangeCode" v-clipboard:success="onCopy" v-clipboard:error="onError" type="button">复制绑定码</button>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading" class="loading-box" type="spinner" />
  </div>
</template>

<script>
import { getRedBagList, getRedBagReceive, getGrabUserInfo } from '@/api/redBag';
import { getWxMpConfig } from '@/api/redList';
import { getGameConfig } from '@/api/gameConfig';
// import { wxLoginInfo } from '@/api/wxlogin';
import { getUserInfo } from '@/api/redList';
import { Loading, Dialog } from 'vant';
import { getDeviceType } from '@/utils';
export default {
  name: 'GrabRed',
  components: {
    Loading,
  },
  filters: {
    countDownFilters(time) {
      const hours = parseInt((time / 1000 / 60 / 60) % 24);
      const minutes = parseInt((time / 1000 / 60) % 60);
      const seconds = parseInt((time / 1000) % 60);
      const getTime = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
      return getTime;
    },
  },
  data() {
    return {
      app_id: window.sessionStorage.getItem('appid'),
      token: window.sessionStorage.getItem('ato'),
      bagList: [],
      timer: null,
      bagForm: {
        show: false,
        isGetBag: false,
      },
      isRefresh: false, //记录刷新列表的按钮动画状态
      showBindGuide: false, //绑定微信弹窗的开关字段
      timeId: '',
      balance: '',
      exchangeCode: 'xxxxxxx',
      isReceive: false,
      isLoading: false, //是否显示loading
      isLoaded: false, //是否继续加载
      last_id: 0,
      wechat: null,
      isAnchorAccount: false, //是否是主播账号
      userInfo: {},
    };
  },
  mounted() {
    this.httpGetUserInfo(); //获取微信绑定信息
    this.getAccountInfo(); //获取是否是主播账号
    document.addEventListener('visibilitychange', function () {
      console.log('document.visibilityState=' + document.visibilityState);
      console.log('document.hidden=' + document.hidden, document.hidden ? '页面隐藏' : '页面显示');
      if (document.visibilityState == 'hidden') {
        // 页面状态变化为不可见时触发
      }
      if (document.visibilityState == 'visible') {
        getUserInfo({
          app_id: window.sessionStorage.getItem('appid'),
          token: window.sessionStorage.getItem('ato'),
        })
          .then((res) => {
            //关闭微信弹窗时,重新获取微信的绑定状态
            res.data.data.wechat ? (this.wechat = res.data.data.wechat) : (this.wechat = null);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },
  activated() {
    this.handleRefresh();
  },
  beforeDestroy() {
    clearInterval(this.timeId);
  },
  methods: {
    closeWXDialog() {
      //关闭绑定微信弹窗
      this.showBindGuide = false;
      getUserInfo({
        app_id: window.sessionStorage.getItem('appid'),
        token: window.sessionStorage.getItem('ato'),
      })
        .then((res) => {
          //关闭微信弹窗时,重新获取微信的绑定状态
          res.data.data.wechat ? (this.wechat = res.data.data.wechat) : (this.wechat = null);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAccountInfo() {
      getGrabUserInfo({
        app_id: this.app_id,
        token: this.token,
        os: getDeviceType(),
        channel_id: window.sessionStorage.getItem('channel_id'),
        pf: window.sessionStorage.getItem('pf'),
      }).then((res) => {
        console.log('Xxxxxxx', res);
        if (res.data.status === 200) {
          this.isAnchorAccount = res.data.data.can_send_redbag === 1;
        }
      });
    },

    //重置列表参数
    resetListParams() {
      this.bagList.splice(0, this.bagList.length);
      this.isLoading = false;
      this.isLoaded = false;
      this.last_id = 0;
    },
    scrollElement(e) {
      const scrollTop = e.target.scrollTop; //滚动距离
      const contaienrHeight = this.$refs['gradContainer'].clientHeight; //视口高度
      const listHeight = this.$refs['header'].clientHeight + this.$refs['wechat'].clientHeight + this.$refs['content'].clientHeight;
      if (scrollTop > listHeight - contaienrHeight - 5) {
        console.log('到底部了', this.isLoading);
        if (this.isLoading) return;
        if (this.isLoaded) return; //没有数据了
        this.last_id = this.bagList[this.bagList.length - 1].id;
        this.getList();
      }
    },
    toGoDetail() {
      this.$router.push({
        path: '/bagDetailed',
        query: {
          redbag_id: this.bagForm.id,
        },
      });
      this.bagForm.show = false;
      this.isReceive = false;
    },
    goRedBagDetailed() {
      this.$router.push({ name: 'GetDetailed' });
    },
    goRedBagConfig() {
      this.$router.push({ name: 'ConfigRed' });
    },
    getList() {
      this.isLoading = true;
      getRedBagList({
        app_id: this.app_id,
        token: this.token,
        last_id: this.last_id,
        os: getDeviceType(),
        channel_id: window.sessionStorage.getItem('channel_id'),
        pf: window.sessionStorage.getItem('pf'),
        openid: window.sessionStorage.getItem('openid'),
      })
        .then((res) => {
          console.log('红包列表', res);
          if (res.data.status === 200) {
            this.bagList.push(...res.data.data);
            clearInterval(this.timer);
            this.timer = null;
            this.setCountDown(); //默认触发一次计算倒计时的函数
            //定时器跑倒计时
            clearInterval(this.timer);
            this.timer = setInterval(this.setCountDown, 1000);
            if (res.data.data.length < 15) {
              this.isLoaded = true;
            }
          } else {
            this.$toast(res.data.msg);
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    setCountDown() {
      // console.log(this.bagList);
      const result = this.bagList.map((e, i) => {
        const nowDate = new Date() - 0; //当前时间戳
        const start_at_ts = e.start_at_ts * 1000; //红包开始时间戳
        //已经开始不显示倒计时
        let _result = Object.assign({}, e);
        if (nowDate > start_at_ts) {
          _result.countDown = 0;
        } else {
          _result.countDown = start_at_ts - nowDate;
        }
        return _result;
      });
      this.bagList.splice(0, this.bagList.length, ...result);
    },
    handleRedBag(item) {
      //如果没有绑定微信默认打开绑定微信弹窗
      if (!this.wechat) {
        Dialog.confirm({
          message: ' 绑定微信后才能领取红包，是否绑定微信账号？',
          confirmButtonColor: '#2897FB',
        })
          .then(() => {
            // on confirm
            this.toWxBind();
            return;
          })
          .catch(() => {
            // on cancel
            return;
          });
        return;
      }
      if (item.countDown > 0) {
        this.$toast('倒计时结束才能领取红包喔~');
        return;
      }
      if (item.level > 1 && item.level > Number(this.userInfo.role.level)) {
        this.$toast('角色等级不满足');
        return;
      }
      const hourse = Math.floor(((new Date() - 0) / 1000 - this.userInfo.role.created_role_time) / 3600);
      console.log(item.first_role_create_hours, hourse);
      if (item.first_role_create_hours > 0 && item.first_role_create_hours < hourse) {
        this.$toast('首次创角时间不满足');
        return;
      }
      Object.assign(this.bagForm, item);
      if (this.bagForm.highlight === 0) {
        this.$router.push({
          path: '/bagDetailed',
          query: {
            redbag_id: this.bagForm.id,
          },
        });
        return;
      }
      this.bagForm.show = true;
      this.isReceive = false;
    },
    closeDialog() {
      this.bagForm.show = false;
      this.bagForm.isGetBag = false;
      this.isReceive = false;
    },
    //
    handleClickBag() {
      this.bagForm.isGetBag = true;
      getRedBagReceive({
        app_id: this.app_id,
        token: this.token,
        redbag_id: this.bagForm.id,
        os: getDeviceType(),
        channel_id: window.sessionStorage.getItem('channel_id'),
        pf: window.sessionStorage.getItem('pf'),
        openid: window.sessionStorage.getItem('openid'),
      }).then((res) => {
        console.log('getRedBagReceive', res);
        if (res.data.status === 200) {
          if (res.data.data?.type === 'without') {
            //已领完
            this.isReceive = true;
            const index = this.bagList.findIndex((e) => this.bagForm.id === e.id);
            this.bagList[index].highlight = 0;
          } else {
            //领取红包成功逻辑,领取详情
            this.closeDialog();
            this.$router.push({
              path: '/bagDetailed',
              query: {
                redbag_id: this.bagForm.id,
              },
            });
          }
        } else {
          this.$toast(res.data.msg);
        }
        this.bagForm.isGetBag = false;
      });
    },
    // 微信绑定
    toWxBind() {
      console.log('XXXX');
      if (this.wechat) return;
      // window.getWxParams = function (data) {
      //   data = JSON.parse(data);
      //   console.log('data', data);
      //   console.log('session', window.sessionStorage);
      //   wxLoginInfo({
      //     app_id: window.sessionStorage.getItem('appid'),
      //     code: data.code,
      //     token: window.sessionStorage.getItem('ato'),
      //   })
      //     .then((res) => {
      //       // window.location.reload()
      //       window.sessionStorage.setItem('getUserInfo', 1);
      //       window.android ? window.android.showToast(res.data.msg) : '';
      //       if (res.data.msg == '审核中') {
      //         let msg = `本次提现${this.moneyValue[this.currentIndex]}元，将于24小时内审核到账（可在明细查询审核进度）`;
      //         this.$toast(msg, { duration: 2500 });
      //       } else {
      //         this.$toast(res.data.msg, { duration: 1500 });
      //       }
      //       // this.$bus.$emit("getUserInfoEvent")
      //       console.log('微信绑定', res.data);
      //     })
      //     .catch((err) => {
      //       console.log('错误', err);
      //     });
      // };
      let that = this;
      if (!that.timeId) {
        that.timeId = setInterval(function () {
          if (window.sessionStorage.getItem('getUserInfo')) {
            // that.$bus.$emit("getUserInfoEvent")
            that.httpGetUserInfo();
            clearInterval(that.timeId);
          }
        }, 300);
      }
      if (!window.android) {
        getGameConfig({
          app_id: window.sessionStorage.getItem('appid'),
          token: window.sessionStorage.getItem('ato'),
        }).then((res) => {
          console.log('res: ', res);
          const { data: content } = res;
          const { data, status } = content;
          if (status === 200) {
            if (data.bind_type === 'jump') {
              window.android.callWeixinLogin();
            } else {
              this.showBindGuidePage();
            }
          }
        });
      } else {
        this.showBindGuidePage();
      }
    },
    // 获取微信信息
    httpGetUserInfo() {
      getUserInfo({
        app_id: window.sessionStorage.getItem('appid'),
        token: window.sessionStorage.getItem('ato'),
      })
        .then((res) => {
          console.log('获取微信信息', res, res.data.data.wechat);
          res.data.data.wechat ? (this.wechat = res.data.data.wechat) : (this.wechat = null);
          Object.assign(this.userInfo, res.data.data);
          this.balance = res.data.data.balance;
          //如果没有绑定微信默认打开绑定微信弹窗
          if (!this.wechat) {
            Dialog.confirm({
              message: ' 绑定微信后才能领取红包，是否绑定微信账号？',
              confirmButtonColor: '#2897FB',
            })
              .then(() => {
                // on confirm
                this.toWxBind();
              })
              .catch(() => {
                // on cancel
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //打开绑定微信弹窗
    showBindGuidePage() {
      getWxMpConfig({
        app_id: window.sessionStorage.getItem('appid'),
        token: window.sessionStorage.getItem('ato'),
      }).then((res) => {
        if (res.data.status === 200) {
          this.exchangeCode = res.data?.data?.bind_key;
          this.showBindGuide = true;
        } else {
          this.$toast(res.data.msg);
        }
      });
    },
    //刷新页面列表逻辑
    handleRefresh() {
      if (this.isRefresh) return; //正在请求中拦截连续点击
      this.isRefresh = true; //打开旋转动画
      this.timer = null; //重置定时器
      getRedBagList({
        app_id: this.app_id,
        token: this.token,
        last_id: 0,
        os: getDeviceType(),
        channel_id: window.sessionStorage.getItem('channel_id'),
        pf: window.sessionStorage.getItem('pf'),
        openid: window.sessionStorage.getItem('openid'),
      })
        .then((res) => {
          if (res.data.status === 200) {
            //重置列表参数
            this.resetListParams();
            this.bagList.splice(0, this.bagList.length, ...res.data.data);
            this.setCountDown(); //默认触发一次计算倒计时的函数
            //定时器跑倒计时
            clearInterval(this.timer);
            this.timer = setInterval(this.setCountDown, 1000);
            if (res.data.data.length < 15) {
              this.isLoaded = true;
            }
          } else {
            this.$toast(res.data.msg);
          }
          this.isRefresh = false;
        })
        .catch((err) => {
          this.isRefresh = false;
        });
    },
    onCopy() {
      this.$toast('复制成功');
    },
    onError() {
      this.$toast('复制失败');
    },
    goBack() {
      this.$router.push({
        path: '/home',
      });
    },
  },
};
</script>

<style lang="less" scoped>
// div {
//   box-sizing: border-box;
// }
.grad-container {
  background-color: #f7f7f7;
  padding: 0 0.28rem;
  height: 100vh;
  overflow-y: auto;
  .header {
    position: relative;

    .back {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 0.36rem;
      .back-icon {
        width: 0.2rem;
        height: 0.29rem;
        background-image: url('../../assets/imgs/back.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .title {
      text-align: center;
      font-size: 0.34rem;
      font-weight: 500;
      color: #222222;
      line-height: 1.35rem;
    }
  }
  .binding-wechat {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #fefefe;
    box-shadow: 0px 0.02rem 0.01rem 0px rgba(200, 200, 200, 0.1);
    border-radius: 0.05rem;
    padding: 0.3rem 0.36rem;
    margin-bottom: 0.32rem;
    .wechat-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .wechat-icon {
        width: 0.51rem;
        height: 0.42rem;
        background-image: url('../../assets/imgs/wechat-no.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 0.18rem;
        &.on {
          background-image: url('../../assets/imgs/wechat-on.png');
        }
      }
      .wechat-text {
        font-size: 0.3rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.32rem;
      }
    }
    .wechat-right {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .wechat-tips {
        margin-right: 0.12rem;
        font-size: 0.26rem;
        font-weight: 500;
        color: #8d8d8d;
        line-height: 0.32rem;
      }
      .wechat-go-icon {
        width: 0.17rem;
        height: 0.23rem;
        background-image: url('../../assets/imgs/wechat-go.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  .content {
    padding-top: 0.32rem;
    background-color: #fefefe;
    box-shadow: 0px 0.02rem 0.01rem 0px rgba(200, 200, 200, 0.1);
    border-radius: 0.05rem;
    padding: 0 0.36rem;
    .hand-red {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0.18rem 0 0.18rem 0.15rem;
      .hadn-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hand-red-icon {
          width: 1.03rem;
          height: 1.24rem;
          background-image: url('../../assets/imgs/live-red.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 0.44rem;
        }
        .hand-text {
          font-size: 0.28rem;
          font-weight: 500;
          color: #222222;
          line-height: 0.39rem;
        }
      }
      .hand-go-icon {
        width: 0.24rem;
        height: 0.35rem;
        background-image: url('../../assets/imgs/go-red.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .border-gray {
      margin: 0 0.07rem;
      // height: 0.02rem;
      border: 0.01rem solid #f7f7f7;
      box-shadow: 0px 0px 0.01rem 0px rgba(0, 0, 0, 0.06);
    }
    .red-list-header {
      padding: 0.18rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .red-list-title {
        font-size: 0.28rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.39rem;
      }
      .red-detailed {
        display: flex;
        align-items: center;
        margin-right: -0.22rem;
        .detailed-tag {
          padding: 0.08rem;
          background-color: #f1f1f1;
          border-radius: 0.07rem;
          font-size: 0.26rem;
          font-weight: 500;
          color: #f14231;
          line-height: 0.39rem;
          margin-right: 0.2rem;
        }
        .refresh {
          padding: 0.1rem 0.22rem;
          .refresh-icon {
            width: 0.33rem;
            height: 0.32rem;
            background-image: url('../../assets/imgs/refresh.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            &.on {
              animation-name: Zxuanzhuan;
              animation-duration: 3s;
              animation-iteration-count: 5;
            }
          }
        }
      }
    }
    .red-list {
      padding: 0.08rem 0;
      min-height: 7rem;
      .item-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.36rem;
        .item {
          width: 3.85rem;
          background-color: #ff9a30;
          padding: 0.18rem 0.24rem 0.06rem;
          border-radius: 0.08rem;
          position: relative;
          box-sizing: border-box;
          .item-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            &.on {
              background-color: #fff;
              opacity: 0.7;
            }
          }
          .top {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            .red-icon {
              width: 0.55rem;
              height: 0.65rem;
              background-image: url('../../assets/imgs/red-check.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center center;
              margin-right: 0.22rem;
              &.on {
                background-image: url('../../assets/imgs/red-checked.png');
              }
            }
            .red-text {
              font-size: 0.26rem;
              font-weight: 500;
              color: #ffffff;
              line-height: 0.32rem;
            }
          }
          .middle {
            &.border {
              margin: 0.24rem auto 0;
              width: 100%;
              height: 0.02rem;
              background-image: url('../../assets/imgs/split-line.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
          .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0.06rem;
            .user {
              display: flex;
              align-items: center;
              .user-head {
                width: 0.27rem;
                height: 0.27rem;
                background-image: url('../../assets/imgs/logo.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 0.12rem;
              }
              .user-name {
                font-size: 0.18rem;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.32rem;
              }
            }
            .red-name {
              font-size: 0.2rem;
              font-weight: 400;
              color: #ffffff;
              line-height: 0.32rem;
            }
          }
        }
        .tips-info {
          font-size: 0.2rem;
          font-weight: 400;
          color: #8d8d8d;
          // line-height: 0.32rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          // margin-left: 0.18rem;
          width: 2.13rem;
          .tips-timer {
            display: flex;
            align-items: center;
            margin-bottom: 0.12rem;
            .timer-icon {
              width: 0.21rem;
              height: 0.21rem;
              background-image: url('../../assets/imgs/timer.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center center;
              margin-right: 0.06rem;
            }
            .tiemr-text {
            }
          }
          .tips-grade {
            display: flex;
            align-items: center;
            margin-bottom: 0.12rem;
            .grade-icon {
              width: 0.21rem;
              height: 0.21rem;
              background-image: url('../../assets/imgs/grade.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center center;
              margin-right: 0.06rem;
            }
            .grade-text {
            }
          }
          .tips-first {
            display: flex;
            align-items: center;
            .first-icon {
              width: 0.21rem;
              height: 0.21rem;
              background-image: url('../../assets/imgs/create-role.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center center;
              margin-right: 0.06rem;
            }
            .first-text {
            }
          }
        }
      }
    }
    .bottom-tips {
      text-align: center;
      font-size: 0.28rem;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
}
.mask {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.65;
  z-index: 999;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4.2rem;
  padding-top: 1.09rem;
  background-image: url('../../assets/imgs/open-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  .user-name {
    font-size: 0.28rem;
    font-weight: 400;
    color: #f5efba;
    line-height: 0.28rem;
  }
  .bag-tips {
    margin-top: 0.26rem;
    font-size: 0.32rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #f5efba;
    line-height: 0.32rem;
  }
  .bag-icon {
    margin-top: 1.47rem;
    margin-bottom: 0.73rem;
    width: 1.38rem;
    height: 1.39rem;
    background-image: url('../../assets/imgs/open-botton.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    &.active {
      animation-name: xuanzhuan;
      animation-duration: 9s;
      animation-iteration-count: 5;
    }
  }
  .go-detailed {
    margin-top: 2.75rem;
    margin-bottom: 0.19rem;
    font-size: 0.24rem;
    font-weight: 400;
    color: #f5efba;
    display: flex;
    align-items: center;
    padding: 0.2rem;
    .detailed-text {
    }
    .detailed-icon {
      margin-left: 0.07rem;
      width: 0.13rem;
      height: 0.17rem;
      background-image: url('../../assets/imgs/Luck.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .colse-box {
    position: absolute;
    top: 110%;
    padding: 0.4rem;
    .close-dialog {
      width: 0.48rem;
      height: 0.48rem;
      background-image: url('../../assets/imgs/close-dialog.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      animation-timing-function: linear;
    }
  }
}

@keyframes xuanzhuan {
  0% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(360deg);
  }
  40% {
    transform: rotateY(0deg);
  }
  60% {
    transform: rotateY(360deg);
  }
  80% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes Zxuanzhuan {
  0% {
    transform: rotateZ(0deg);
  }
  20% {
    transform: rotateZ(360deg);
  }
  40% {
    transform: rotateZ(720deg);
  }
  60% {
    transform: rotateZ(1080deg);
  }
  80% {
    transform: rotateZ(1440deg);
  }
  100% {
    transform: rotateZ(1800deg);
  }
}
.wx-bind {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 111;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .bind-shade {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(97, 90, 90, 0.255);
  }
  .bind-content {
    position: absolute;
    z-index: 111;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 0.6rem 0.4rem;
    width: 5.4rem;
    border-radius: 0.3rem;
    .title {
      font-size: 0.36rem;
      color: #454545;
      text-align: center;
      margin-bottom: 0.34rem;
      font-weight: 400;
    }
    .text > p {
      font-size: 0.28rem;
      color: #454545;
      line-height: 0.4rem;
      margin-bottom: 0.14rem;
    }
    .close {
      position: absolute;
      top: 0.24rem;
      right: 0.34rem;
      font-size: 0.38rem;
      width: 0.32rem;
      height: 0.32rem;
      color: #898787;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .exchange {
      text-align: center;
      margin-top: 0.5rem;
      > p {
        font-size: 0.28rem;
        color: #454545;
        margin-bottom: 0.21rem;
      }
      > button {
        border: none;
        outline: none;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        width: 2.6rem;
        height: 0.8rem;
        text-align: center;
        background-color: #2897fb;
        color: #fff;
        font-size: 0.3rem;
        border-radius: 0.4rem;
      }
    }
  }
  .light-text {
    font-size: 0.3rem;
    color: #ff942b;
  }
}
.loading-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
