import { render, staticRenderFns } from "./GrabRed.vue?vue&type=template&id=8f9b2852&scoped=true&"
import script from "./GrabRed.vue?vue&type=script&lang=js&"
export * from "./GrabRed.vue?vue&type=script&lang=js&"
import style0 from "./GrabRed.vue?vue&type=style&index=0&id=8f9b2852&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f9b2852",
  null
  
)

export default component.exports