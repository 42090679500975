import _axios from './request';
// import Qs from "qs"
import { buildParams } from '@/utils/sign';

// data: {
//     app_id: 100001,
//     channel_id: 1001,
//     role_id: 1001,
//     role_name: 1001,
//     server_id: 1001,
//     server_name: 1001,
//     level: 101,
//     sign
// }
export function getGameConfig(data) {
  // console.log(Qs.stringify(data))
  data = buildParams(data);
  return _axios({
    method: 'get',
    url: '/v2/config/game',
    //   data: Qs.stringify(data)
    params: data,
  });
}
